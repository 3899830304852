@import '~@salesforce-ux/design-system/scss/_design-tokens.scss';

html {
  background: transparent;
}

.bullet {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 4px;
}

.bullet-row {
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    color: $color-text-label;
    font-size: $font-size-4;
  }
}

.score-rings div {
  margin: auto;
}
